import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Properties',
  methods: {
    ...mapActions(["fetchProperties"]),
    addProperty() {
      this.$router.push({ path: "/p/properties/new" })
    },
    goToProperty(propertyId) {
      this.$router.push({ path: `/p/properties/${propertyId}` })
    }
  },
  computed: {
    ...mapGetters(["properties", "units"]),
    occupied() {
      let occupied = 0;
      this.properties.forEach(property => {
        property.units.forEach(unit => {
          unit.status == "Occupied" ? occupied += 1 : null;
        })
      });
      return occupied;
    },
    vacant() {
      let vacant = 0;
      this.properties.forEach(property => {
        property.units.forEach(unit => {
          unit.status == "Available" ? vacant += 1 : null;
        })
      });
      return vacant;
    },
    unlisted() {
      let unlisted = 0;
      this.properties.forEach(property => {
        property.units.forEach(unit => {
          unit.is_published == false ? unlisted += 1 : null;
        })
      });
      return unlisted;
    },
  },
  created() {
    this.fetchProperties()
  }
}