import router from '../../router'
import { APIService } from '../../services/api.service'
import { AuthService } from '../../services/auth.service'
import { ResponseToObjectService } from '../../services/response-to-object'

const apiService = new APIService()
const authService = new AuthService()
const responseToObjectService = new ResponseToObjectService()

const getDefaultState = () => {
    return {
        requests: Array()
    }
}

const state = getDefaultState()

const getters = {
    requests: state => state.requests,
}

const actions = {
    async fetchRequests({ dispatch, commit }) {
        dispatch('load')
        await apiService.request('get', `activities/property_owner_request/${authService.getUser().id}`).then((requests) => {
            commit('setRequests', requests)
            dispatch('unload')
        })
    },
    async fetchTenantRequests({ dispatch, commit }) {
        dispatch('load')
        const requests = await apiService.request('get', `activities/tenant_request/${authService.getUser().id}`)
        commit('setRequests', requests)
        dispatch('unload')
    },
    async requestAction({ dispatch }, postData) {
        dispatch('load')
        if (postData.type == 'Resolve') {
            await apiService.request('post', 'activities/request/resolve/', postData)
            dispatch('unload')
        } else if (postData.type == 'Open') {
            await apiService.request('post', 'activities/request/open/', postData)
            dispatch('unload')
        }
        dispatch('fetchRequests')
    },
    async newRequest({ dispatch }, postData) {
        dispatch('load')
        try{
            await apiService.request('post', 'activities/requests/', postData).then(() => {
                dispatch('fetchRequests').then(() => {
                    router.push({path: '/u/requests'})
                    dispatch('successAlert', 'Request made successfully')
                    dispatch('unload')
                })
            })
            dispatch('unload')
        } catch {
            dispatch('unload')
            dispatch('errorAlert', 'An unknown error has occured')   
        }
    },
    async resetRequests({ commit }) {
        commit('resetState')
    }


}

const mutations = {
    setRequests: (state, requests) => {
        state.requests = responseToObjectService.convertToRequestsArray(requests);
        state.requests.sort((a, b) => b.date - a.date)
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}