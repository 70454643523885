import PropertyOwner from "../classes/propertyOwner";
import Tenant from "../classes/tenant";

export class AuthService {
    getUser() {
        if (this.getAccount() == 'Property Owner') {
            return new PropertyOwner(JSON.parse(localStorage.getItem('user')))
        } else if (this.getAccount() == 'Tenant') {
            return new Tenant(JSON.parse(localStorage.getItem('user')))
        }
    }

    setUser(user) {
        localStorage.setItem('user', JSON.stringify(user))
    }

    setPropertyOwner(propertyOwner) {
        localStorage.setItem('propertyOwner', propertyOwner)
    }
    
    getPropertyOwner() {
        return localStorage.getItem('propertyOwner')
    }
    
    setAccount(account) {
        localStorage.setItem('account', account)
    }

    getAccount() {
        return localStorage.getItem('account');
    }

    getToken() {
        let token = JSON.parse(localStorage.getItem('urT'));
        return token;
    }

    setToken(token) {
        let accessToken = token.access;
        let refreshToken = token.refresh;
        let urT = {
            access: accessToken,
            refresh: refreshToken
        }
        let userToken = JSON.stringify(urT)
        localStorage.setItem('urT', userToken)
    }
    
    destroyToken() {
        localStorage.removeItem('urT')
        localStorage.removeItem('user')
        localStorage.removeItem('propertyOwner')
    }
}