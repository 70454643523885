import { AuthService } from "./auth.service";
import axios from 'axios';

const authService = new AuthService();


export class APIService {
    request(method, url, data) {
        authService.getToken() == null ? this.headerToken = '' : this.headerToken = `Bearer ${authService.getToken().access}`

        const config = {
            url: `${process.env.VUE_APP_API_URL}${url}`,
            method: method,
            data: data,

            headers: {
                'content-type': 'application/json',
                'Authorization': this.headerToken
            }
        }

        return axios(config)
    }
}