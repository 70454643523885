import router from '../../router'
import { APIService } from '../../services/api.service'
import { AuthService } from '../../services/auth.service'
import { ResponseToObjectService } from '../../services/response-to-object'

const apiService = new APIService()
const authService = new AuthService()
const responseToObjectService = new ResponseToObjectService()

const getDefaultState = () => {
    return {
        properties: Array(),
        rents: Array(),
        allRent: Array(),
        dashboard: Array(),
        units: Number(),
    }
}

const state = getDefaultState()

const getters = {
    properties: state => state.properties,
    rents: state => state.rents,
    allRent: state => state.allRent,
    dashboard: state => state.dashboard,
    units: state => state.units,
}

const actions = {
    async fetchProperties({ dispatch, commit }) {
        dispatch('load')
        await apiService.request('get', `filter_properties/${authService.getUser().id}`).then((properties) => {
            commit('setProperties', properties)
            dispatch('unload')
        })
    },
    async fetchTenants({ dispatch, commit }) {
        dispatch('load')
        await apiService.request('get', `activities/rent/${authService.getUser().id}/active`).then((rents) => {
            commit('setTenants', rents)
            dispatch('unload')
        })
    },
    async fetchAllRent({ dispatch, commit }) {
        dispatch('load')
        await apiService.request('get', `activities/rent/${authService.getUser().id}/all`).then((allRent) => {
            commit('setAllRent', allRent)
            dispatch('unload')
        })
    },
    async newProperty({ dispatch }, postData) {
        dispatch('load')
        try {
            const property = await apiService.request('post', `properties/`, postData)
            return dispatch('fetchProperties').then(() => {
                dispatch('unload')
                dispatch('successAlert', 'Property created successfully')
                router.push({ path: `/p/properties/${property.data.property_id}/units` })
            })
        } catch {
            dispatch('unload')
            return dispatch('errorAlert', 'Something went wrong')
        }
    },
    async deleteProperty({ dispatch }, postData) {
        dispatch('load')
        try {
            await apiService.request('delete', `properties/${postData}`).then(() => {
                router.push({ path: `/p/properties` })
                dispatch('unload')
                return dispatch('successAlert', 'Property deleted successfully')
            })
        } catch {
            dispatch('unload')
            return dispatch('errorAlert', 'Something went wrong')
        }
    },
    async deleteUnit({ dispatch }, postData) {
        dispatch('load')
        try {
            await apiService.request('delete', `units/${postData}`)
            return dispatch('fetchProperties').then(() => {
                dispatch('unload')
            })
        } catch {
            dispatch('unload')
            return dispatch('errorAlert', 'Something went wrong')
        }
    },
    async uploadUnits({ dispatch }, postData) {
        try {
            await postData.existingUnits.forEach((unit) => {
                apiService.request('patch', `units/${unit.id}/`, unit)
            })
            await postData.newUnits.forEach((unit) => {
                apiService.request('post', 'units/', unit)
            })
            return dispatch('successAlert', 'Units Saved').then(() => {
                router.push({ path: `/p/properties/${postData.property}` })
            })
        } catch {
            dispatch('unload')
            return dispatch('errorAlert', 'Something went wrong')
        }
    },
    async renewRent({ dispatch }, postData) {
        dispatch('load')
        try {
            await apiService.request('post', 'activities/rent/renew/', postData)
            await dispatch('successAlert', 'Rent renewed successfully')
            await dispatch('unload')
        } catch {
            await dispatch('errorAlert', 'Something went wrong')
            await dispatch('unload')
        }
    },
    async evictTenant({ dispatch }, postData) {
        dispatch('load')
        try {
            await apiService.request('delete', `activities/rent/delete/${postData}`)
            await dispatch('successAlert', 'Tenant removed from unit successfully')
            router.push({ path: '/p/tenants' })
            await dispatch('unload')
        } catch {
            await dispatch('errorAlert', 'Something went wrong')
            await dispatch('unload')
        }
    },
    async resetProperties({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setProperties: (state, properties) => {
        state.properties = responseToObjectService.convertToPropertiesArray(properties);
        state.dashboard = state.properties.slice(0, 2);
        state.units = 0
        state.properties.forEach(property => {
            state.units += property.units.length;
            property.vacant = 0
            property.occupied = 0
            property.unlisted = 0
            property.units.forEach(unit => {
                if (unit.status == "Available") {
                    property.vacant++
                }
                if (unit.status == "Occupied") {
                    property.occupied++
                }
                if (!unit.is_published) {
                    property.unlisted++
                }
            })
        });
    },
    setTenants: (state, rents) => {
        state.rents = responseToObjectService.convertToRentsArray(rents);
    },
    setAllRent: (state, allRent) => {
        state.allRent = responseToObjectService.convertToRentsArray(allRent);
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}