export class Alert {
    message = String;
    type = String;
    show = Boolean;

    constructor(type, message) {
        this.type = type
        this.message = message
        this.show = false;
    }

    success(message) {
        return new Alert('success', message)
    }
    
    error(message) {
        return new Alert('error', message)
    }
    
    warning(message) {
        return new Alert('warning', message)
    }
}