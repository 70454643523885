import { Role } from "./role.js";
import User from "./user.js"

export default class PropertyOwner {
    id = String;
    avi = String;
    bank_name = String;
    account_number = String;
    business_name = String;
    subaccount_code = String
    profile = User

    constructor(propertyOwner) {
        this.id = propertyOwner.property_owner_id;
        this.avi = propertyOwner.avi || "https://res.cloudinary.com/yugee-properties/image/upload/v1620743890/default-avi1.jpg";
        this.bank_name = propertyOwner.bank_name;
        this.account_number = propertyOwner.account_number;
        this.subaccount_code = propertyOwner.subaccount_code;
        this.profile = new User(propertyOwner.user, Role.propertyOwner);
        this.business_name = propertyOwner.business_name || `${this.profile.firstName} ${this.profile.lastName} Properties`;
    }
}