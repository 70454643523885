import { AuthService } from "../../services/auth.service";

export default {
  name: 'Home',
  data() {
    const account = "undefined"
    return {
      account,
    }
  },
  methods: {
    modify(account) {
      this.account = account;
      new AuthService().setAccount(account)
      localStorage.setItem('account', this.account)
    }
  },
  created() {
    this.$store.dispatch('unload')
    this.account = localStorage.getItem('account')
  },
  mounted() {
  },
  beforeDestroy() {
  }
}