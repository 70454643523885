import { mapActions, mapGetters } from 'vuex'
import Tenant from '../../classes/tenant'
import { AuthService } from '../../services/auth.service'

export default {
    name: 'AddTenant',
    props: {
        propertyId: {
            type: String,
            required: true
        }
    },
    components: {},
    data() {
        return {
            tenant_first_name: '',
            tenant_last_name: '',
            tenant_email: '',
            tenant_phone: '',
            tenant_date_of_birth: '',
            tenant_occupation: '',
            tenant_next_of_kin_full_name: '',
            tenant_next_of_kin_phone_number: '',
            tenant_next_of_kin_address: '',
            tenant_identification: '',
            optional_fees: false,
            rent_paid: false,
            initial: true,
            agreement: '',
            other_document: '',
            unit: '',
            exists: false,
            tenant: Tenant,
            id_display_image: 'https://images.unsplash.com/photo-1487637419635-a2a471ff5c7b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2052&q=80',
            agreement_display_image: 'https://images.unsplash.com/photo-1521791055366-0d553872125f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
            other_display_image: 'https://images.unsplash.com/photo-1488485282435-e2ad51917a76?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1834&q=80',
        }
    },
    methods: {
        ...mapActions(['newTenant', "fetchProperties", "verifyEmail", 'newRent', 'tenantDetails', 'initializeRent']),
        addTenant() {
            if (this.tenant_identification == '' || this.agreement == '') {
                this.$store.dispatch('warningAlert', 'Please upload required documents')
            } else if (this.exists) {
                let postData = {
                    tenant: this.tenant.id,
                    unit: this.unit[0],
                    optional_fees: this.optional_fees,
                    rent_paid: this.rent_paid,
                    identification_card: this.tenant_identification,
                    signed_agreement: this.agreement,
                    other_document: this.other_document,
                }

                if (this.initial) {
                    this.initializeRent(postData)
                } else {
                    this.newRent(postData)
                }

            } else {
                let tenant = new FormData()
                let profile = new FormData()
                let rent = new FormData()

                rent.append('unit', this.unit[0])
                rent.append('optional_fees', this.optional_fees)
                rent.append('rent_paid', this.rent_paid)
                rent.append('identification_card', this.tenant_identification)
                rent.append('signed_agreement', this.agreement)
                rent.append('other_document', this.other_document)

                tenant.append('date_of_birth', this.tenant_date_of_birth)
                tenant.append('occupation', this.tenant_occupation)
                tenant.append('next_of_kin_full_name', this.tenant_next_of_kin_full_name)
                tenant.append('next_of_kin_phone_number', this.tenant_next_of_kin_phone_number)
                tenant.append('next_of_kin_address', this.tenant_next_of_kin_address)

                profile.append('first_name', this.tenant_first_name)
                profile.append('last_name', this.tenant_last_name)
                profile.append('email', this.tenant_email.toLowerCase())
                profile.append('phone_number', this.tenant_phone)
                profile.append('password', process.env.VUE_APP_API_URL)
                profile.append('re_password', process.env.VUE_APP_API_URL)

                let postData = {
                    'tenant': tenant,
                    'profile': profile,
                    'rent': rent,
                }
                this.newTenant(postData)
            }
        },
        verifyMail() {
            if (this.tenant_email != '') {
                this.tenantDetails(this.tenant_email.toLowerCase()).then((tenant) => {
                    if (tenant) {
                        this.exists = true;
                        this.tenant = tenant
                    } else {
                        this.exists = false
                    }
                })
            }
        },
        goToUnits() {
            this.$router.push({ path: `/p/properties/${this.propertyId}/units` })
        },
        upload(doc) {
            document.getElementById(doc).click()
        },
        uploadID(event) {
            this.tenant_identification = event.target.files[0];
        },
        uploadAgreement(event) {
            this.agreement = event.target.files[0];
        },
        uploadOtherDoc(event) {
            this.other_document = event.target.files[0];
        }
    },
    computed: {
        ...mapGetters(["properties"]),
        availableUnits() {
            let units = []
            this.properties.forEach(property => {
                let tempUnits = property.units.filter(
                    unit => unit.status === "Available"
                )
                tempUnits.forEach(unit => {
                    units.push([unit.id, `${property.name} - ${unit.title}`, unit.price, unit.optional_fees])
                })
            });
            return units
        },
        user() {
            return new AuthService().getUser()
        }
    },
    created() {
        this.fetchProperties()
    },
    beforeDestroy() {}
}