import { mapActions } from "vuex"
import { Role } from "../../classes/role";
import { AuthService } from "../../services/auth.service";

export default {
  name: 'Settings',
  methods: {
    ...mapActions(['logOut', 'logoutLoading']),
    logout() {
      this.logoutLoading();
      this.logOut();
    },
    goToPage(page) {
      let toPage = page
      if (new AuthService().getAccount() == Role.tenant) {
        toPage = page + '-Tenant'
      }
      this.$router.push({ name: toPage })
    }
  },
}