import Vue from 'vue'
import { sync } from 'vuex-router-sync'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Unicon from 'vue-unicons'
import VueDragscroll from 'vue-dragscroll'
import Spinner from '@/components/Spinner/spinner.component.vue'
import Dropdown from '@/components/Dropdown/dropdown.component.vue'
import Avatar from 'vue-avatar-component'

import { cacheAdapterEnhancer } from 'axios-extensions';
import { uniSearch, uniAngleDown, uniAngleUp, uniApps, uniBuilding, uniUsersAlt, uniCheckCircle, uniCreditCard, uniSetting, uniClock, uniArrowCircleRightMonochrome, uniPlusCircle, uniToiletPaper, uniBed, uniLayers, uniEllipsisV, uniAngleRight, uniAngleLeft, uniPlus, uniSortAmountUp, uniSortAmountDown, uniFilter, uniTimes, uniQuestionCircle, uniTimesCircleMonochrome, uniCheckCircleMonochrome, uniTrashAlt, uniCreateDashboard, uniStoreSlashMonochrome, uniExclamationOctagonMonochrome, uniExclamationTriangleMonochrome, uniArrowRight, uniPen, uniEye, uniImport, uniKeySkeleton, uniKeyholeCircle, uniCheck, uniArrowUp, uniPostcard, uniFileContractDollar, uniDocumentInfo, uniMoneyStack, uniUser, uniShieldCheck, uniSignout, uniTriangleMonochrome } from 'vue-unicons/src/icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { AuthService } from './services/auth.service'
import { mapGetters } from 'vuex'

sync(store, router)

require('es6-promise').polyfill();

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('validator', ValidationProvider)
Vue.component('observer', ValidationObserver)
Vue.component('Spinner', Spinner)
Vue.component('Dropdown', Dropdown)
Vue.component('Avatar', Avatar)

Unicon.add([uniSearch, uniAngleDown, uniAngleUp, uniApps, uniBuilding, uniUsersAlt, uniCheckCircle, uniCreditCard, uniSetting, uniClock, uniArrowCircleRightMonochrome, uniPlusCircle, uniToiletPaper, uniBed, uniLayers, uniEllipsisV, uniAngleRight, uniAngleLeft, uniPlus, uniSortAmountUp, uniSortAmountDown, uniFilter, uniTimes, uniQuestionCircle, uniTimesCircleMonochrome, uniCheckCircleMonochrome, uniTrashAlt, uniCreateDashboard, uniStoreSlashMonochrome, uniExclamationOctagonMonochrome, uniExclamationTriangleMonochrome, uniArrowRight, uniPen, uniEye, uniImport, uniKeySkeleton, uniKeyholeCircle, uniCheck, uniArrowUp, uniPostcard, uniFileContractDollar, uniDocumentInfo, uniMoneyStack, uniUser, uniShieldCheck, uniSignout, uniTriangleMonochrome])

Vue.use(Unicon)
Vue.use(require('vue-moment'));
Vue.use(VueDragscroll)
Vue.use(VueAxios, axios.create({
  adapter: cacheAdapterEnhancer(axios.defaults.adapter)
}));

var numeral = require('numeral')

Vue.filter("naira", function (value) {
  return `₦ ${numeral(value).format("0,0")}`
})

Vue.mixin({
  methods: {
    navigateBack() {
      this.$router.go(-1)
    },
    goTo(page) {
      if (this.$route.name != page) {
        this.$router.push({ name: page })
      }
    }
  },
  data: function () {
    return {
      authService: new AuthService(),
      today: new Date()
    }
  },
  computed: {
    ...mapGetters(['loading'])
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
