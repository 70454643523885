import { mapActions } from "vuex"
import { AuthService } from "../../../services/auth.service"

export default {
  name: 'Request-New',
  props: {
  },
  components: {
  },
  data() {
    return {
      title: '',
      description: '',
      type: '',
      requestTypes: [ 'Maintenance', 'Other' ]
    }
  },
  methods: {
    ...mapActions(['requestAction', 'newRequest']),
    goToRequests() {
      this.$router.push({path: `/u/requests`})
    },
    open() {
      if (this.title != '' && this.description != '' && this.type != '') {
        let formData = new FormData()
        formData.append('title', this.title)
        formData.append('description', this.description)
        formData.append('type', this.type)
        formData.append('status', 'Pending')
        formData.append('tenant', new AuthService().getUser().id)
        formData.append('property_owner', new AuthService().getPropertyOwner())
        this.newRequest(formData)
      } else {
        this.$store.dispatch('warningAlert', 'Please complete request fields')
      }
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
