export default {
  name: 'Dropdown',
  props: {
    actions: Array,
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    performAction(action) {
      this.$emit('performAction', action)
    },
    hideDropDown() {
      this.show = false
    },
    toggleDropDown() {
      this.show = !this.show
    }
  },
  created() {
  }
}