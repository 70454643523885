import router from "../../router/index"
import { ResponseToObjectService } from '../../services/response-to-object'
import { APIService } from '../../services/api.service'
import { AuthService } from '../../services/auth.service'
import store from ".."
import { Role } from "../../classes/role"

const authService = new AuthService()
const responseToObjectService = new ResponseToObjectService()
const apiService = new APIService()

const getDefaultState = () => {
  return {
    user: Object()
  }
}

const state = getDefaultState()

const getters = {
  user: state => state.user,
}

const actions = {
  async logIn({ dispatch }, postData) {
    dispatch('noauthload');
    authService.destroyToken();
    try {
      const response = await apiService.request('post', 'auth/jwt/create/', postData)
      authService.setToken(response.data)
      return dispatch('authenticate').then((isValid) => {
        if (isValid) {
          dispatch('successAlert', 'Logged in successfully')
          authService.getAccount() == Role.propertyOwner ? router.push({ name: 'Dashboard' }) : router.push({ name: 'Dashboard: Tenant' })
        }
        dispatch('unload')
      })
    } catch {
      dispatch('errorAlert', 'Credentials provided are invalid. Please try again.')
      dispatch('unload')
    }
  },
  async verifyEmail({ dispatch }, postData) {
    dispatch('noauthload');
    try {
      await apiService.request('get', `api/user/verify/${postData}`)
      dispatch('unload')
      return true
    } catch {
      dispatch('errorAlert', 'User with this email already exists')
      dispatch('unload')
      return false
    }
  },
  async register({ dispatch }, postData) {
    dispatch('noauthload')
    try {
      const response = await apiService.request('post', 'auth/users/', postData)
      dispatch('unload')
      return response
    } catch {
      dispatch('unload')
      dispatch('errorAlert', 'Something went wrong')
    }
  },
  async changePassword({ dispatch }, postData) {
    dispatch('noauthload')
    const response = await apiService.request('patch', 'change_password', postData)
    if (response.data.status == 'success') {
      dispatch('unload');
      dispatch('successAlert', response.data.message)
      let credentials = new FormData()
      credentials.append('password', postData.get('new_password'))
      credentials.append('email', new AuthService().getUser().profile.email)
      dispatch('logIn', credentials)
    } else {
      store.dispatch('errorAlert', response.data.message)
      dispatch('unload');
    }
  },
  async updateProfile({ dispatch }, postData) {
    dispatch('load')
    try {
      if (postData.role == Role.propertyOwner) {
        await apiService.request('patch', 'property_owner/change_avi/', postData.user)
      } else {
        await apiService.request('patch', 'tenant/change_avi/', postData.user)
      }
      dispatch('unload');
      dispatch('authenticate');
      store.dispatch('successAlert', 'Profile Updated successfully')
    } catch {
      store.dispatch('errorAlert', 'Profile cannot be updated at this time. Please try again later.')
      dispatch('unload');
    }
  },
  async forgotPassword({ dispatch }, postData) {
    dispatch('noauthload')
    try {
      const response = await apiService.request('post', 'reset_password/', postData)
      dispatch('successAlert', response.data).then(() => {
        dispatch('unload')
        router.push({ path: '/' })
      })
    } catch {
      dispatch('unload')
      dispatch('errorAlert', 'Email is not registered').then(() => {})
    }
  },
  async resetPassword({ dispatch }, data) {
    dispatch('noauthload')
    try {
      const response = await apiService.request('post', `reset_password_done/${data.uid}/${data.token}/`, data.postData)
      dispatch('successAlert', response.data).then(() => {
        dispatch('unload')
        router.push({ name: 'Login' })
      })
    } catch {
      dispatch('errorAlert', 'Link is invalid').then(() => {
        dispatch('unload')
      })
    }
  },
  async activateAccount({ dispatch }, postData) {
    dispatch('noauthload')
    try {
      if (postData.account == Role.tenant) {
        const response = await apiService.request('post', `activate_tenant/${postData.uid}/${postData.token}/`, postData.passwords)
        dispatch('successAlert', response.data).then(() => {
          dispatch('unload')
          router.push({ name: 'Login' })
        })
      } else {
        const response = await apiService.request('post', `activate_property_owner/${postData.uid}/${postData.token}/`)
        dispatch('successAlert', response.data).then(() => {
          dispatch('unload')
          router.push({ name: 'Login' })
        })
      }
    } catch {
      dispatch('errorAlert', 'Link is invalid').then(() => {
        dispatch('unload')
      })
    }
  },
  async authenticate({ dispatch, commit }) {
    const details = await apiService.request('get', 'auth/users/me/')
    let profile = responseToObjectService.convertToUser(details);
    const user = await apiService.request('get', `authenticate/${profile.id}`)
    if (authService.getAccount() == Role.propertyOwner) {
      if (user.data.role == Role.propertyOwner) {
        commit('setUser', user)
        return true
      } else {
        dispatch('errorAlert', 'Account does not belong to any Registered Property Owner').then(() => {
          commit('logOut')
          return false
        })
      }
    } else if (authService.getAccount() == Role.tenant) {
      if (user.data.role == Role.tenant) {
        commit('setUser', user)
        return true
      } else {
        dispatch('errorAlert', 'Account does not belong to any Registered Tenant').then(() => {
          commit('logOut')
          return false
        })
      }
    }

  },
  async refresh({ dispatch, commit }) {
    if (authService.getToken()) {
      try {
        const token = await apiService.request('post', 'auth/jwt/refresh', { refresh: authService.getToken().refresh })
        let userToken = {
          access: token.data.access,
          refresh: authService.getToken().refresh
        }
        authService.setToken(userToken)
      } catch {
        dispatch('errorAlert', 'Invalid Token')
        commit('logOut')
        router.push({ name: 'Auth' })

      }
    }
  },
  async registerPropertyOwner({ dispatch }, postData) {
    dispatch('noauthload')
    apiService.request('post', 'property_owners/', postData).then((propertyOwner) => {
      apiService.request('post', 'activities/subaccount/', { 'property_owner': propertyOwner.data.property_owner_id })
    })
    dispatch('unload')
  },
  async newTenant({ dispatch }, postData) {
    dispatch('load')
    try {
      await dispatch('register', postData.profile).then((user) => {
        dispatch('load')
        postData.tenant.append('user', user.data.user_id)
        apiService.request('post', 'tenants/', postData.tenant).then((tenant) => {
          dispatch('successAlert', 'Tenant account created successfully')
          postData.rent.append('tenant', tenant.data.tenant_id)
          apiService.request('post', 'activities/rent/initialize/', postData.rent).then(() => {
            dispatch('unload')
            router.push({ path: '/p/properties' })
          })
        })
      })

    } catch {
      dispatch('unload')
      return dispatch('errorAlert', 'Something went wrong')
    }
  },
  async initializeRent({ dispatch }, postData) {
    dispatch('load')
    try {
      await apiService.request('post', 'activities/rent/initialize/', postData)
      await dispatch('unload')
      router.push({ path: '/p/properties' })
    } catch {
      dispatch('unload')
      return dispatch('errorAlert', 'Something went wrong')
    }
  },
  async newRent({ dispatch }, postData) {
    dispatch('load')
    try {
      await apiService.request('post', 'activities/rent/new/', postData)
      await dispatch('unload')
      router.push({ path: '/p/properties' })
    } catch {
      dispatch('unload')
      return dispatch('errorAlert', 'Something went wrong')
    }
  },
  async verifyAccountNumber({ dispatch }, postData) {
    dispatch('noauthload')
    const account_number = await apiService.request('post', 'activities/account_number/', postData)
    if (account_number.data.account_number.status) {
      dispatch('successAlert', 'Account Number Verified')
      dispatch('unload')
      return account_number.data.account_number.data.account_name
    } else {
      dispatch('errorAlert', 'Invalid account number. Pleae try again')
      dispatch('unload')
    }
  },
  async logOut({ dispatch, commit }) {
    commit('logOut')
    router.push({ name: 'Auth' })
    dispatch('resetProperties')
    dispatch('resetRequests')
    dispatch('resetTenant')
  }
}

const mutations = {
  setUser: (state, user) => {
    authService.setUser(user.data.user);
    if (user.data.role == Role.propertyOwner) {
      state.user = responseToObjectService.convertToPropertyOwner(user)
    } else {
      state.user = responseToObjectService.convertToTenant(user)
    }
  },
  logOut: (state) => {
    authService.destroyToken()
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}