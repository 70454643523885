export class DarkModeService {

    get() {
        return JSON.parse(localStorage.getItem('darkMode'))
    }

    toggle() {
        let mode = this.get();
        this.set(!mode)
    }

    set(mode) {
        localStorage.setItem('darkMode', mode)
        this.init()
    }
    
    init() {
        if (this.get()) {
            document.body.style.setProperty('--background', '#222C3C');
            document.body.style.setProperty('--background-alt', '#171E29');
            document.body.style.setProperty('--black', '#FFFFFF');
        } else {
            document.body.style.setProperty('--background', '#FFFFFF');
            document.body.style.setProperty('--background-alt', '#F9F9F9');
            document.body.style.setProperty('--black', '#000000');
        }
    }
}