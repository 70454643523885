import { Alert } from '../../classes/alert'

const state = {
    alert: new Alert()
}

const getters = {
    alert: state => state.alert,
}

const actions = {
    successAlert({ commit }, message) {
        const alert = new Alert().success(message)
        commit('showAlert', alert)
    },
    errorAlert({ commit }, message) {
        const alert = new Alert().error(message)
        commit('showAlert', alert)
    },
    warningAlert({ commit }, message) {
        const alert = new Alert().warning(message)
        commit('showAlert', alert)
    },
}

const mutations = {
    showAlert: (state, alert) => {
        state.alert = alert
        state.alert.show = true
        setTimeout(() => {
            state.alert.show = false;
        }, 3000);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}