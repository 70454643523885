export default class User {
    id = String;
    firstName = String;
    lastName = String;
    email = String;
    phoneNumber = String;
    role = String;

    constructor(user, role) {
        this.id = user.user_id;
        this.firstName = user.first_name;
        this.lastName = user.last_name;
        this.email = user.email.toLowerCase();
        this.phoneNumber = user.phone_number;
        this.role = role;
    }

    fullName() {
        return `${this.firstName} ${this.lastName}`
    }
}