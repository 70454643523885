import { mapActions } from "vuex"

export default {
  name: 'RequestDetail-Tenant',
  props: {
    requestId: {
      type: String,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchTenantRequests', 'requestAction']),
    goToRequests() {
      this.$router.push({path: `/u/requests`})
    },
  },
  computed: {
    request() {
      return this.$store.state.requests.requests.find(
        request => request.id === this.requestId
      )
    },
  },
  created() {
    this.fetchTenantRequests();
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
