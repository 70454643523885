import { mapActions, mapGetters } from "vuex"

export default {
  name: 'Tenants',
  props: {
  },
  components: {
  },
  data() {
    return {
      page: 1,
      perPage: 6,
      start: 1,
      end: 1,
      filter: 'all',
      pageList: []
    }
  },
  methods: {
    ...mapActions(['fetchAllRent']),
    goToTenant(tenantId) {
      this.$router.push({ path: `/p/tenants/${tenantId}` })
    },
    addTenant() {
      this.$router.push({ path: "/p/tenants/new" })
    },
    filterBy(by) {
      this.filter = by;
      switch (by) {
        case 'all':
          this.pageList = this.tenants;
          break;
        case 'active':
          this.pageList = this.tenants.filter(
            rent => new Date(rent.terminationDate) > new Date()
          )
          break;
        case 'inactive':
          this.pageList = this.tenants.filter(
            rent => new Date(rent.terminationDate) < new Date()
          )
          break;
      }
    },
    move(direction) {
      switch (direction) {
        case 'back':
          if (this.page > 1) {
            this.page--
          }
          break;
        case 'forward':
          if (this.page < (this.allRent.length / this.perPage)) {
            this.page++
          }
          break;
      }
    }
  },
  computed: {
    ...mapGetters(['allRent']),
    loaded() {
      return (this.loading && this.allRent.length == 0)
    },
    tenants() {
      return Array.from(new Set(this.allRent.map(rent => rent.tenant.id))).map(id => {
        return this.allRent.find(rent => rent.tenant.id === id)
      })
    },
    displayed() {
      let start = (this.page - 1) * this.perPage;
      let end = start + this.perPage;
      let list = this.pageList.slice(start, end)
      this.start = start + 1;
      end > list.length ? this.end = this.start + list.length - 1 : this.end = end;
      return list
    }
  },
  created() {
    this.filterBy('all')
    this.fetchAllRent().then(() => {
      this.filterBy('all');
    })
  },
  beforeDestroy() {
  }
}