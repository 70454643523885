export default {
  name: 'ProgressRing',
  props: {
    radius: Number,
    progress: Number,
    stroke: Number
  },
  data() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const d = new Date()
    var month = new Array();
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    return {
      normalizedRadius,
      circumference,
      currentMonth: month[d.getMonth()]
    };
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - this.progress / 100 * this.circumference;
    }
  },
  methods: {
    extend() {
      this.$emit("extend")
    },
    selected(page) {
      this.isActive = page
      this.$route.name == page ? '' : this.$router.push({ name: page })
    }

  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  }
}