import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home/home.component.vue'
import SeeThrough from '@/pages/SeeThrough/seeThrough.component.vue'
import Auth from '@/pages/Auth/auth.component.vue'
import Login from '@/pages/Login/login.component.vue'
import Register from '@/pages/Register/register.component.vue'
import ResetPassword from '@/pages/ResetPassword/resetPassword.component.vue'
import ActivateAccount from '@/pages/ActivateAccount/activateAccount.component.vue'
import ForgotPassword from '@/pages/ForgotPassword/forgotPassword.component.vue'
import Dashboard from '@/pages/PropertyOwner/Dashboard/dashboard.component.vue'
import Properties from '@/pages/PropertyOwner/Properties/properties.component.vue'
import Tenants from '@/pages/PropertyOwner/Tenants/tenants.component.vue'
import Requests from '@/pages/PropertyOwner/Requests/requests.component.vue'
import Payments from '@/pages/PropertyOwner/Payments/payments.component.vue'

import TenantDashboard from '@/pages/Tenant/Dashboard/dashboard.component.vue'
import TenantRequests from '@/pages/Tenant/Requests/requests.component.vue'
import TenantRequestDetail from '@/pages/Tenant/RequestDetail/requestDetail.component.vue'
import NewRequest from '@/pages/Tenant/NewRequest/newRequest.component.vue'
import Rent from '@/pages/Tenant/Rent/rent.component.vue'

import Settings from '@/pages/Settings/settings.component.vue'
import Profile from '@/pages/Profile/profile.component.vue'
import Help from '@/pages/Help/help.component.vue'
import ChangePassword from '@/pages/ChangePassword/changePassword.component.vue'
import Error from '@/pages/Error/error.component.vue'
import PropertyDetail from '@/components/PropertyDetail/propertyDetail.component.vue'
import AddProperty from '@/components/AddProperty/addProperty.component.vue'
import UnitDetail from '@/components/UnitDetail/unitDetail.component.vue'
import TenantDetail from '@/components/TenantDetail/tenantDetail.component.vue'
import AddTenant from '@/components/AddTenant/addTenant.component.vue'
import RequestDetail from '../components/RequestDetail/requestDetail.component.vue'
import { AuthService } from '../services/auth.service'

Vue.use(VueRouter)

const REDIRECT_TIME_LIMIT = 3600000;

const redirect = () => {
  const defaultRoute = '/';
  const local = JSON.parse(window.localStorage.getItem('vuex'));
  const isIos = () => window.navigator.userAgent.indexOf('iPhone') !== -1;
  const isStandalone = () => window.navigator.standalone;
  if (!local) {
    return defaultRoute;
  }

  const { route, lastLaunch } = local;
  const now = new Date().valueOf();

  if (isIos() && isStandalone() && now - lastLaunch < REDIRECT_TIME_LIMIT) {
    return route.path;
  }

  return defaultRoute;
}

const routes = [{
    path: '/',
    component: Home,
    children: [{
      path: '',
      name: 'Auth',
      component: Auth
    }, {
      path: 'login',
      name: 'Login',
      component: Login
    }, {
      path: 'register',
      name: 'Register',
      component: Register
    }, {
      path: 'forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword
    }, {
      path: 'reset-password/:uid/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      props: true
    }, {
      path: 'activate-account/:uid/:token',
      name: 'ActivateAccount',
      component: ActivateAccount,
      props: true
    }],
    meta: {
      guest: true
    },
    redirect: redirect()
  },
  {
    path: '/p/',
    component: SeeThrough,
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: Dashboard,
    }, {
      path: 'properties',
      name: 'Properties',
      component: Properties,
    }, {
      path: 'properties/new',
      name: 'Property: Add',
      component: AddProperty,
    }, {
      path: 'properties/:propertyId',
      name: 'Property: Detail',
      component: PropertyDetail,
      props: true,
    }, {
      path: 'properties/:propertyId/units',
      name: 'Property: UnitDetail',
      component: UnitDetail,
      props: true,
    }, {
      path: 'tenants',
      name: 'Tenants',
      component: Tenants,
    }, {
      path: ':propertyId/tenants/new',
      name: 'Tenant: Add',
      component: AddTenant,
      props: true,
    }, {
      path: 'tenants/:tenantId',
      name: 'Tenant: TenantDetail',
      component: TenantDetail,
      props: true,
    }, {
      path: 'requests',
      name: 'Requests',
      component: Requests,
    }, {
      path: 'requests/:requestId',
      name: 'Requests: RequestDetail',
      component: RequestDetail,
      props: true,
    }, {
      path: 'payments',
      name: 'Payments',
      component: Payments,
    }, {
      path: 'profile',
      name: 'Settings: Profile',
      component: Profile,
    }, {
      path: 'change-password',
      name: 'Settings: ChangePassword',
      component: ChangePassword,
    }, {
      path: 'help',
      name: 'Settings: Help',
      component: Help,
    }, {
      path: 'settings',
      name: 'Settings',
      component: Settings,
    }, {
      path: '*',
      redirect: '/p/dashboard'
    }],
    meta: {
      requiresAuth: true,
      isPropertyOwner: true
    }
  },
  {
    path: '/u/',
    component: SeeThrough,
    children: [{
      path: 'dashboard',
      name: 'Dashboard: Tenant',
      component: TenantDashboard,
    }, {
      path: 'rent',
      name: 'Rent: Tenant',
      component: Rent,
    }, {
      path: 'requests',
      name: 'Requests: Tenant',
      component: TenantRequests,
    }, {
      path: 'requests/new',
      name: 'Requests: New-Tenant',
      component: NewRequest,
    }, {
      path: 'requests/:requestId',
      name: 'Requests: RequestDetail-Tenant',
      component: TenantRequestDetail,
      props: true,
    }, {
      path: 'profile',
      name: 'Settings: Profile-Tenant',
      component: Profile,
    }, {
      path: 'change-password',
      name: 'Settings: ChangePassword-Tenant',
      component: ChangePassword,
    }, {
      path: 'help',
      name: 'Settings: Help-Tenant',
      component: Help,
    }, {
      path: 'settings',
      name: 'Settings: Tenant',
      component: Settings,
    }],
    meta: {
      requiresAuth: true,
      isPropertyOwner: false
    }
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/u/',
    redirect: '/u/dashboard'
  },
  {
    path: '/p/',
    redirect: '/p/dashboard'
  },
  {
    path: '/404',
    name: 'Error',
    component: Error,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.isPropertyOwner)) {
    if (new AuthService().getAccount() == "Property Owner") {
      next()
    } else if (new AuthService().getAccount() == "Tenant") {
      next({ path: '/u/dashboard' })
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (new AuthService().getToken() == null) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (new AuthService().getToken() == null) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  } else {
    next()
  }

  if (to.name == 'Login' && from.path != '/') {
    const token = new AuthService().getToken()
    if (token) {
      next({ name: from.name })
    }
  }
})

export default router