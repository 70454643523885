import { mapActions, mapGetters } from "vuex"
import ProgressBar from "@/components/ProgressBar/progress-bar.component.vue"

export default {
  name: 'Rent-Tenant',
  props: {
  },
  components: {
    ProgressBar,
  },
  data() {
    return {
      paying: false
    }
  },
  methods: {
    ...mapActions(['fetchRentHistory', 'activeRent', 'payRent']),
    pay() {
      this.paying = true;
      let formData = new FormData()
      formData.append('rent', this.currentRent.id)
      this.payRent(formData).then(() => {
        this.paying = false;
      });
    }
  },
  computed: {
    ...mapGetters(['rentHistory', 'activeProperty']),
    currentRent() {
      return this.activeProperty.rent
    },
    progress() {
      if (this.currentRent) {
        return (this.currentRent.amountPaid / this.currentRent.total_amount) * 100
      } else {
        return 0
      }
    }
  },
  created() {
    this.fetchRentHistory();
    this.activeRent();
  },
  beforeDestroy() {
  }
}