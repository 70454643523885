import axios from "axios";
import { mapActions } from "vuex"
import { AuthService } from "../../services/auth.service";

export default {
  name: 'Profile',
  props: {},
  components: {},
  data() {
    return {
      banks: Array(),
      accountName: '',
      display_image: '',
      uploaded_image: '',
      bank: ''
    }
  },
  methods: {
    ...mapActions(['verifyAccountNumber', 'updateProfile']),
    callBanks() {
      const bankRequest = axios.get('https://api.paystack.co/bank')
      bankRequest.then((bank) => {
        bank.data.data.forEach(bank => {
          this.banks.push(bank)
          if (bank.code == this.user.bank_name) {
            this.bank = bank.name
          }
        });
      })
    },
    verifyAccount() {
      let account_number = new FormData()
      if (this.user.account_number.length == 10) {
        account_number.append('account_number', this.user.account_number)
        account_number.append('bank_name', this.user.bank_name)
        this.verifyAccountNumber(account_number).then((account_name) => {
          this.accountName = account_name
        }).catch(() => {
          this.accountName = ''
        })
      }
    },
    updatePropertyOwnerProfile() {
      let property_owner = new FormData()
      property_owner.append('property_owner_id', this.user.id)
      property_owner.append('avi', this.uploaded_image)
      let postData = {
        id: this.user.id,
        role: 'Property Owner',
        user: property_owner
      }
      this.updateProfile(postData)
    },
    updateTenantProfile() {
      let tenant = new FormData()
      tenant.append('tenant_id', this.user.id)
      tenant.append('avi', this.uploaded_image)
      let postData = {
        id: this.user.id,
        role: 'Tenant',
        user: tenant
      }
      this.updateProfile(postData)
    },
    uploadImage() {
      document.getElementById('image').click()
    },
    selectImage(event) {
      let uploaded = event.target.files[0];
      this.display_image = URL.createObjectURL(uploaded)
      this.uploaded_image = uploaded
    }
  },
  computed: {
    user() {
      return new AuthService().getUser()
    }
  },
  created() {
    this.callBanks();
  },
  beforeDestroy() {}
}