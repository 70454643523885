export default {
  name: 'ProgressBar',
  props: {
    progress: Number
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  watch: { 
    progress: function() {
      document.documentElement.style.setProperty("--progress", `${this.progress}%`);
    }
  },
  created() {
    document.documentElement.style.setProperty("--progress", `${this.progress}%`);
  },
  beforeDestroy() {
  }
}