export default class Unit {
    id = String;
    title = String;
    price = String;
    toilets = Number;
    bedrooms = Number;
    bathrooms = Number;
    square_feet = Number;
    status = String;
    payment_frequency = Number;
    renewal = Boolean;
    is_serviced = Boolean;
    is_published = Boolean;
    optional_fees = Number;
    optional_fees_description = Text;
    property = String;

    constructor(unit) {
        this.id = unit.unit_id;
        this.title = unit.title;
        this.price = unit.price;
        this.toilets = 0;
        this.bedrooms = 0;
        this.bathrooms = 0;
        this.square_feet = 0;
        this.status = unit.status;
        this.payment_frequency = unit.payment_frequency;
        this.renewal = unit.renewal;
        this.is_serviced = unit.is_serviced;
        this.is_published = unit.is_published;
        this.optional_fees = unit.optional_fees || 0;
        this.optional_fees_description = unit.optional_fees_description;
        this.property = unit.property;
    }

    totalPrice() {
        return +this.price + +this.optional_fees
    }
}