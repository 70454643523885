export default {
  name: 'Navigation',
  props: {
    isExtended: Boolean,
  },
  components: {
  },
  data() {
    return {
      propertyOwnerPages: [
        ['Dashboard', 'apps'],
        ['Properties', 'building'],
        ['Tenants', 'users-alt'],
        ['Requests', 'check-circle'],
        ['Payments', 'credit-card'],
        ['Settings', 'setting'],
      ],
      tenantPages: [
        ['Dashboard', 'apps'],
        ['Rent', 'credit-card'],
        ['Requests', 'check-circle'],
        ['Settings', 'setting'],
      ],
    }
  },
  methods: {
    extend() {
      this.$emit("extend")
    },
    selected(page) {
      if(!this.$route.matched[0].meta.isPropertyOwner) {
        page = `${page}: Tenant`
      }
      this.$route.name == page ? '' : this.$router.push({ name: page })
    }
  },
  computed: {
    isActive() {
      return this.$route.name;
    },
    pages() {
      let pages = []
      if(this.$route.matched[0].meta.isPropertyOwner) {
        pages = this.propertyOwnerPages;
      } else {
        pages = this.tenantPages;
      }
      return pages;
    }

  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  }
}