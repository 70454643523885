import { mapActions } from "vuex"
import { AuthService } from "../../services/auth.service";

export default {
  name: 'Help',
  props: {},
  components: {},
  data() {
    return {
      banks: Array(),
      currentPassword: '',
      password: '',
      confirmPassword: '',
    }
  },
  methods: {
    ...mapActions(['changePassword']),
    updatePassword() {
      let postData = new FormData()
      postData.append('old_password', this.currentPassword)
      postData.append('new_password', this.password)
      postData.append('confirm_password', this.confirmPassword)
      this.changePassword(postData)
    }
  },
  computed: {
    user() {
      return new AuthService().getUser()
    }
  },
  created() {},
  beforeDestroy() {}
}