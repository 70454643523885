import { mapActions, mapGetters } from 'vuex'
import ProgressRing from "@/components/ProgressRing/progress-ring.component.vue"
import ProgressBar from "@/components/ProgressBar/progress-bar.component.vue"

export default {
  name: 'Dashboard',
  props: {
    isExtended: Boolean
  },
  components: {
    ProgressRing,
    ProgressBar,
  },
  data() {
    const months = new Array();
    months[0] = "January";
    months[1] = "February";
    months[2] = "March";
    months[3] = "April";
    months[4] = "May";
    months[5] = "June";
    months[6] = "July";
    months[7] = "August";
    months[8] = "September";
    months[9] = "October";
    months[10] = "November";
    months[11] = "December";

    return {
      months,
      rentFilterMonth: new Date().getMonth(),
      rentFilterYear: new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions(["fetchProperties", "fetchTenants", "fetchRequests"]),
    extend() {
      this.$emit("extend")
    },
    addProperty() {
      this.$router.push({ path: "/p/properties/new" })
    },
    goToProperty(property_id) {
      this.$router.push({ path: `/p/properties/${property_id}` })
    },
    goToRequest(requestId) {
      this.$router.push({ path: `/p/requests/${requestId}` })
    }
  },
  computed: {
    ...mapGetters(["properties", "requests"]),
    thisMonth() {
      return this.$store.state.properties.rents.filter(
        rent => new Date(rent.terminationDate).getMonth() == this.rentFilterMonth && new Date(rent.terminationDate).getFullYear() == this.rentFilterYear
      )
    },
    collected() {
      let collected = 0;
      this.thisMonth.forEach(rent => {
        collected += rent.amountPaid
      });
      return collected;
    },
    upcomingRequests() {
      return this.$store.state.requests.requests.slice(0, 3)
    },
    total() {
      let total = 0;
      this.thisMonth.forEach(rent => {
        total += rent.total_amount
      })
      return total;
    },
    progress() {
      return (this.collected / this.total) * 100 || 0
    },

  },
  created() {
    this.fetchProperties();
    this.fetchTenants();
    this.fetchRequests();
  },
  mounted() {
  },
  beforeDestroy() {
  }
}