var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth",attrs:{"id":"reset-password"}},[(_vm.account == 'Tenant')?_c('div',[_c('h2',[_vm._v("Activate your Account")]),_c('h4',{staticClass:"subheader"},[_vm._v("Please create a new password to get set up.")]),_c('observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validator',{staticClass:"input",attrs:{"rules":{
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          },"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('span',[_vm._v("New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{ 'is-true': errors[0] },attrs:{"required":"","autocomplete":"new-password","type":"password","name":"password","placeholder":"Enter password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(errors[0])?_c('p',[_vm._v(" Password must have at least 8 characters, containing lowercase and uppercase letters, numbers, and symbols. ")]):_vm._e()]}}],null,true)}),_c('validator',{staticClass:"input",attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('span',[_vm._v("Confirm new Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rePassword),expression:"rePassword"}],class:{ 'is-true': errors[0] },attrs:{"type":"password","name":"rePassword","placeholder":"Confirm your new password"},domProps:{"value":(_vm.rePassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.rePassword=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('button',{staticClass:"button",attrs:{"type":"submit"}},[_c('div',{staticClass:"button-icon"},[(_vm.loading)?_c('Spinner'):_c('unicon',{staticClass:"icon",attrs:{"name":"key-skeleton"}})],1),_c('span',{staticClass:"action"},[_vm._v(" Submit ")])])],1)]}}],null,false,1770311585)})],1):_c('div',[_c('h2',[_vm._v("Activate your Account")]),_c('h4',{staticClass:"subheader"},[_vm._v("Click the button below to get activate your account and get started with us!")]),_c('button',{staticClass:"button activate",on:{"click":_vm.submit}},[_c('div',{staticClass:"button-icon"},[(_vm.loading)?_c('Spinner'):_c('unicon',{staticClass:"icon",attrs:{"name":"key-skeleton"}})],1),_c('span',{staticClass:"action"},[_vm._v(" Activate your Account ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }