import { render, staticRenderFns } from "./forgotPassword.component.vue?vue&type=template&id=301b460f&scoped=true&"
import script from "./forgotPassword.component.js?vue&type=script&lang=js&"
export * from "./forgotPassword.component.js?vue&type=script&lang=js&"
import style0 from "./forgotPassword.component.scss?vue&type=style&index=0&id=301b460f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301b460f",
  null
  
)

export default component.exports