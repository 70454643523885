import { mapActions, mapGetters } from "vuex"

export default {
  name: 'Payments',
  props: {
  },
  components: {
  },
  data() {
    return {
      page: 1,
      perPage: 6,
      start: 1,
      end: 1,
      filter: 'all',
      pageList: [],
      sorted: false
    }
  },
  methods: {
    ...mapActions(['fetchAllRent']),
    filterBy(by) {
      this.filter = by;
      switch (by) {
        case 'all':
          this.pageList = this.allRent;
          break;
        case 'active':
          this.pageList = this.allRent.filter(
            rent => new Date(rent.terminationDate) > new Date() && rent.amountPaid == rent.total_amount
          )
          break;
        case 'incomplete':
          this.pageList = this.allRent.filter(
            rent => rent.amountPaid < rent.total_amount
          )
          break;
        case 'paid':
          this.pageList = this.allRent.filter(
            rent => new Date(rent.terminationDate) < new Date() && rent.amountPaid == rent.total_amount
          )
          break;
      }
    },
    move(direction) {
      switch (direction) {
        case 'back':
          if (this.page > 1) {
            this.page--
          }
          break;
        case 'forward':
          if (this.page < (this.allRent.length / this.perPage)) {
            this.page++
          }
          break;
      }
    },
    sort() {
      this.pageList.sort(
        (a, b) => new Date(a.terminationDate) - new Date(b.terminationDate)
      )
      this.sorted = true;
    },
    reverse() {
      this.pageList.reverse();
      this.sorted = false;
    },
  },
  computed: {
    ...mapGetters(['allRent']),
    displayed() {
      let start = (this.page - 1) * this.perPage;
      let end = start + this.perPage;
      let list = this.pageList.slice(start, end)
      this.start = start + 1;
      end > list.length ? this.end = this.start + list.length - 1 : this.end = end;
      return list
    }
  },
  created() {
    this.filterBy('all')
    this.fetchAllRent().then(() => {
      this.filterBy('all')
    })
  },
  beforeDestroy() {
  }
}