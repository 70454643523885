import { mapActions } from "vuex"

export default {
  name: 'RequestDetail',
  props: {
    requestId: {
      type: String,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchRequests', 'fetchTenants', 'requestAction']),
    error() {
      setTimeout(() => {
        this.property == undefined ? this.$router.push({ name: 'Error' }) : '';
      }, 1000);
    },
    goToRequests() {
      this.$router.push({path: `/p/requests`})
    },
    resolve() {
      let postData = {
        request : this.requestId,
        type: 'Resolve'
      }
      this.requestAction(postData).then(() => {
        this.request.status = 'Resolved'
      })
    },
    open() {
      let postData = {
        request : this.requestId,
        type: 'Open'
      }
      this.requestAction(postData).then(() => {
        this.request.status = 'Open'
      })
    }
  },
  computed: {
    request() {
      return this.$store.state.requests.requests.find(
        request => request.id === this.requestId
      )
    },
  },
  created() {
    this.fetchTenants();
    this.fetchRequests();
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
