var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"unit-detail"}},[(_vm.name)?_c('div',{staticClass:"back",on:{"click":function($event){return _vm.navigateToProperty(_vm.propertyId)}}},[_c('unicon',{staticClass:"icon",attrs:{"name":"angle-left"}}),_c('span',[_vm._v(" Back to "+_vm._s(_vm.name)+" ")])],1):_vm._e(),(!_vm.loading && _vm.units)?_c('observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"header"},[_c('h1',[_vm._v("PROPERTY UNITS")]),_c('button',{staticClass:"button",attrs:{"id":"saveUnits"},on:{"click":function($event){return handleSubmit(_vm.saveUnits)}}},[_c('div',{staticClass:"button-icon"},[_c('unicon',{staticClass:"icon",attrs:{"name":"import"}})],1),_c('span',{staticClass:"action"},[_vm._v(" SAVE")])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"selector"},[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.newUnit()}}},[_c('div',{staticClass:"button-icon"},[_c('unicon',{staticClass:"icon",attrs:{"name":"plus"}})],1),_c('span',{staticClass:"action huge"},[_vm._v(" Add Unit ")])]),_c('div',{staticClass:"selectors"},_vm._l((_vm.units),function(unit){return _c('div',{key:unit.id,staticClass:"unit-selector",class:{ active: unit == _vm.activeUnit },on:{"click":function($event){return _vm.selectUnit(unit)}}},[_c('span',{staticClass:"id"},[_vm._v(" "+_vm._s(_vm.units.indexOf(unit) + 1)+" ")]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(unit.title)+" ")])])}),0)]),_c('div',{staticClass:"units-v"},[(!_vm.loading && _vm.activeUnit)?_c('div',{staticClass:"unit"},[_c('form',{class:{ disabled: _vm.activeUnit.status == 'Occupied' }},[_c('div',{staticClass:"join-inputs"},[_c('validator',{staticClass:"input mid",attrs:{"rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("Unit Title *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeUnit.title),expression:"activeUnit.title"}],class:{
                      'is-true': errors[0],
                      disabled: _vm.activeUnit.status == 'Occupied',
                    },attrs:{"required":"","maxlength":"30","type":"text","name":"Title","disabled":_vm.activeUnit.status == 'Occupied'},domProps:{"value":(_vm.activeUnit.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.activeUnit, "title", $event.target.value)}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validator',{staticClass:"input mid",attrs:{"rules":"required|max_value:9499999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('span',[_vm._v("Unit Price (₦) *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeUnit.price),expression:"activeUnit.price"}],class:{
                      'is-true': errors[0],
                      disabled: _vm.activeUnit.status == 'Occupied',
                    },attrs:{"required":"","max":"99999999","type":"number","min":"0","name":"Price","disabled":_vm.activeUnit.status == 'Occupied'},domProps:{"value":(_vm.activeUnit.price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.activeUnit, "price", $event.target.value)}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"join-inputs"},[_c('validator',{staticClass:"input mid",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('span',[_vm._v(" Payment Frequency * ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeUnit.payment_frequency),expression:"activeUnit.payment_frequency"}],class:{
                      'is-true': errors[0],
                      disabled: _vm.activeUnit.status == 'Occupied',
                    },attrs:{"disabled":_vm.activeUnit.status == 'Occupied'},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.activeUnit, "payment_frequency", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Yearly")])]),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validator',{staticClass:"input mid",attrs:{"rules":"max_value:500000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('span',[_vm._v(" Optional Fees "),_c('unicon',{staticClass:"icon",attrs:{"name":"question-circle"}}),_c('span',{staticClass:"tooltip"},[_vm._v("Additional fees for Electrcity, Water, Security etc")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeUnit.optional_fees),expression:"activeUnit.optional_fees"}],class:{ disabled: _vm.activeUnit.status == 'Occupied' },attrs:{"type":"number","min":"0","name":"Optional Fees","disabled":_vm.activeUnit.status == 'Occupied'},domProps:{"value":(_vm.activeUnit.optional_fees)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.activeUnit, "optional_fees", $event.target.value)}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"join-inputs"},[_c('validator',{staticClass:"input",attrs:{"id":"description"}},[_c('span',[_vm._v(" Optional Fees Description ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeUnit.optional_fees_description),expression:"activeUnit.optional_fees_description"}],class:{ disabled: _vm.activeUnit.status == 'Occupied' },attrs:{"name":"Optional Fees Description","disabled":_vm.activeUnit.status == 'Occupied'},domProps:{"value":(_vm.activeUnit.optional_fees_description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.activeUnit, "optional_fees_description", $event.target.value)}}},[_vm._v("What optional services do you offer with this property")])]),_c('validator',{staticClass:"input",attrs:{"id":"status"}},[(_vm.activeUnit.status === 'Available')?_c('unicon',{staticClass:"icon success",attrs:{"name":"check-circle","icon-style":"monochrome","width":"60px","height":"60px"}}):_c('unicon',{staticClass:"icon error",attrs:{"name":"store-slash","icon-style":"monochrome","width":"60px","height":"60px"}}),_c('span',{class:{
                      success: _vm.activeUnit.status === 'Available',
                      error: _vm.activeUnit.status === 'Occupied',
                    }},[_vm._v(" "+_vm._s(_vm.activeUnit.status)+" ")])],1)],1),_c('div',{staticClass:"join-inputs"},[_c('div',{staticClass:"total"},[_vm._v("TOTAL "),_c('span',{class:{ success: _vm.activeUnit.totalPrice() <= 9999999, error: _vm.activeUnit.totalPrice() > 9999999}},[_vm._v(" "+_vm._s(_vm._f("naira")(_vm.activeUnit.totalPrice()))+" ")])]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"action",class:{ disabled: _vm.activeUnit.status == 'Occupied' },attrs:{"disabled":_vm.activeUnit.status == 'Occupied'}},[_c('unicon',{staticClass:"icon error",attrs:{"name":"trash-alt"},on:{"click":function($event){return _vm.removeUnit(_vm.activeUnit)}}}),_c('span',{staticClass:"error"},[_vm._v("DELETE")])],1),_c('div',{staticClass:"action"},[_c('unicon',{staticClass:"icon",attrs:{"name":"create-dashboard"},on:{"click":function($event){return _vm.duplicateUnit(_vm.activeUnit)}}}),_c('span',[_vm._v("DUPLICATE")])],1)])])])]):_c('div',{staticClass:"empty"},[_c('div',{staticClass:"loader"},[_c('Spinner',{attrs:{"size":2}})],1)])])])]}}],null,false,161190594)}):_c('div',{staticClass:"empty"},[_c('div',{staticClass:"loader"},[_c('Spinner',{attrs:{"size":2}}),_c('span',{staticClass:"load-text"},[_vm._v("Loading Units")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }