import { mapActions, mapGetters } from 'vuex'
import { AuthService } from '../../services/auth.service';

export default {
  name: 'ActivateAccount',
  props: {
    uid: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    },
  },
  components: {
  },
  data() {
    return {
      password: '',
      rePassword: '',
    }
  },
  methods: {
    ...mapActions(['activateAccount']),
    submit() {
      let passwords = new FormData();
      if (this.account == 'Tenant') {
        passwords.append('new_password', this.password)
        passwords.append('confirm_password', this.rePassword)
      }
      let postData = {
        uid: this.uid,
        token: this.token,
        passwords: passwords,
        account: this.account
      }
      this.activateAccount(postData)
    }
  },
  computed: {
    ...mapGetters(["loading"]),
    account() {
      if (this.$route.query.p == 'true') {
        return 'Property Owner'
      } else {
        return 'Tenant'
      }
    }
  },
  created() {
    new AuthService().setAccount(this.account)
  },
  beforeDestroy() {
  }
}