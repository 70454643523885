import PropertyOwner from "./propertyOwner";
import Rent from "./rent";

export default class ActiveProperty {
    id = String;
    name = String;
    main_image = String;
    street_address = String;
    address_line_2 = String;
    city = String;
    state = String;
    property_owner = PropertyOwner;
    rent = Rent;
    
    constructor(property, rent) {
        this.id = property.property_id;
        this.name = property.name || 'Wayne Manor';
        this.main_image = property.main_image;
        this.street_address = property.street_address;
        this.address_line_2 = property.address_line_2;
        this.city = property.city;
        this.state = property.state;
        this.property_owner = property.property_owner;
        this.rent = new Rent(rent)
    }
}