import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ResetPassword',
  props: {
    uid: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    },
  },
  components: {
  },
  data() {
    return {
      password: '',
      rePassword: '',
    }
  },
  methods: {
    ...mapActions(['resetPassword']),
    submit() {
      let postData = new FormData;
      postData.append('new_password', this.password)
      postData.append('confirm_password', this.rePassword)
      let data = {
        uid: this.uid,
        token: this.token,
        postData: postData
      }
      this.resetPassword(data) 
    }
  },
  computed: {
    ...mapGetters(["loading"])
  },
  mounted() {
  },
  beforeDestroy() {
  }
}