import { mapActions } from 'vuex'
import { AuthService } from '../../services/auth.service'

export default {
    name: 'AddProperty',
    props: {},
    components: {},
    data() {
        return {
            property_name: '',
            property_image: '',
            street_address: '',
            address_line_2: '',
            city: '',
            state: '',
            display_image: 'https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80',
        }
    },
    methods: {
        ...mapActions(['newProperty', 'errorAlert']),
        addProperty() {
            if (this.property_image == '') {
                this.errorAlert('Please upload an image for your Property to continue')
            } else {
                let property = new FormData()
                property.append('name', this.property_name)
                property.append('main_image', this.property_image)
                property.append('street_address', this.street_address)
                property.append('address_line_2', this.address_line_2)
                property.append('city', this.city)
                property.append('state', this.state)
                property.append('property_owner', this.user.id)
                this.newProperty(property)
            }
        },
        minimize() {
            this.property_units <= 0 ? this.property_units = 1 : this.property_units
        },
        uploadImage() {
            document.getElementById('image').click()
        },
        selectImage(event) {
            let uploaded = event.target.files[0];
            this.display_image = URL.createObjectURL(uploaded)
            this.property_image = uploaded
        }
    },
    computed: {
        user() {
            return new AuthService().getUser()
        }
    },
    mounted() {},
    beforeDestroy() {}
}