import { faBell, faSearch, faMoon, faSun } from "@fortawesome/free-solid-svg-icons"
import { mapGetters, mapActions } from "vuex"
import { DarkModeService } from "@/services/dark-mode.service.js"
import { AuthService } from "../../services/auth.service"
import ClickOutside from 'vue-click-outside'
import Dropdown from "@/components/Dropdown/dropdown.component.vue"
import { Role } from "../../classes/role"

export default {
  name: 'Header',
  components: {
    Dropdown
  },
  data() {
    const darkModeService = new DarkModeService();
    const darkMode = darkModeService.get()

    return {
      bellIcon: faBell,
      searchIcon: faSearch,
      moonIcon: faMoon,
      sunIcon: faSun,
      search: '',
      dropdown: false,
      darkMode,
      darkModeService,
      actions: ['Profile', 'Logout']
    }
  },
  methods: {
    ...mapActions(['logOut', 'logoutLoading', 'authenticate']),
    searching() {
      this.search == '' ?
        this.$router.push({ name: 'Dashboard' }) :
        this.$router.push({ name: 'Search', query: { q: this.search } })
    },
    toggleDarkMode() {
      this.darkModeService.toggle();
      this.darkMode = !this.darkMode;
    },
    toggle() {
      if (this.$refs.profileDropDown) {
        this.$refs.profileDropDown.toggleDropDown();
        this.dropdown = !this.dropdown
      }
    },
    hide() {
      this.$refs.profileDropDown.hideDropDown();
      this.dropdown = false
    },
    performAction(action) {
      switch (action) {
        case 'Logout':
          this.logout();
          break;
        case 'Profile':
          this.goToPage();
          break;
      }
      this.hide()
    },
    goToPage() {
      let toPage = 'Settings: Profile'
      if (new AuthService().getAccount() == Role.tenant) {
        toPage = toPage + '-Tenant'
      }
      this.$router.push({ name: toPage })
    },
    logout() {
      this.logoutLoading();
      this.logOut();
    },
  },
  computed: {
    ...mapGetters(["loading", "logoutLoader", "user"]),
    account() {
      return new AuthService().getAccount()
    },
  },
  created() {
    this.darkModeService.init();
    this.authenticate();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  directives: {
    ClickOutside
  }
}