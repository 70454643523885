import ActiveProperty from '../../classes/activeProperty'
import Rent from '../../classes/rent'
import { APIService } from '../../services/api.service'
import { AuthService } from '../../services/auth.service'
import { ResponseToObjectService } from '../../services/response-to-object'

const apiService = new APIService()
const authService = new AuthService()
const responseToObjectService = new ResponseToObjectService()

const getDefaultState = () => {
    return {
        activeRent: Rent,
        activeProperty: ActiveProperty,
        rentHistory: Array(),
    }
}

const state = getDefaultState()

const getters = {
    activeRent: state => state.activeRent,
    activeProperty: state => state.activeProperty,
    rentHistory: state => state.rentHistory,
}

const actions = {
    async activeRent({ dispatch, commit }) {
        dispatch('load')
        try {
            const activeRent = await apiService.request('get', `activities/tenant_rent/${authService.getUser().id}/active`)
            const property = await apiService.request('get', `properties/${activeRent.data.activeRent.unit.property}`)
            commit('setProperty', { property, activeRent }).then(() => {
                setTimeout(() => {
                    dispatch('unload')
                }, 100);
            })
        } catch(error) {
            dispatch('unload')
        }
    },
    async fetchRentHistory({ dispatch, commit }) {
        dispatch('load')
        try {
            const history = await apiService.request('get', `activities/tenant_rent/${authService.getUser().id}`);
            commit('setRentHistory', history)
            dispatch('unload')
        } catch (error) {
            dispatch('unload')
        }
    },
    async tenantDetails({ dispatch }, postData) {
        try {
            dispatch('load')
            const details = await apiService.request('get', `tenant/details/${postData}`, )
            let tenant = responseToObjectService.convertToTenant(details);
            dispatch('unload')
            return tenant
        } catch {
            dispatch('unload')
        }
    },
    async payRent(context, postData) {
        const response = await apiService.request('post', 'activities/pay/', postData);
        window.location.href = response.data.authorization_url
    },
    async resetTenant({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setProperty: (state, property) => {
        state.activeProperty = responseToObjectService.convertToActiveProperty(property)
        authService.setPropertyOwner(state.activeProperty.property_owner)
    },
    setRentHistory: (state, history) => {
        state.rentHistory = responseToObjectService.convertToRentsArray(history)
        state.rentHistory.sort((a, b) => b.terminationDate - a.terminationDate)
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}