import { mapActions, mapGetters } from "vuex";

export default {
  name: 'Requests-Tenant',
  props: {
  },
  components: {
  },
  data() {
    return {
      page: 1,
      perPage: 6,
      start: 1,
      end: 1,
      filter: 'all',
      pageList: []
    }
  },
  methods: {
    ...mapActions(['fetchTenantRequests']),
    goToRequest(requestId) {
      this.$router.push({ path: `/u/requests/${requestId}` })
    },
    newRequest() {
      this.$router.push({ path: `/u/requests/new` })
    },
    filterBy(by) {
      this.filter = by;
      switch (by) {
        case 'all':
          this.pageList = this.requests;
          break;
        case 'resolved':
          this.pageList = this.requests.filter(
            request => request.status == "Resolved"
          )
          break;
        case 'open':
          this.pageList = this.requests.filter(
            request => request.status == "Open"
          )
          break;
        case 'pending':
          this.pageList = this.requests.filter(
            request => request.status == "Pending"
          )
          break;
      }
    },
    move(direction) {
      switch (direction) {
        case 'back':
          if (this.page > 1) {
            this.page--
          }
          break;
        case 'forward':
          if (this.page < (this.requests.length / this.perPage)) {
            this.page++
          }
          break;
      }
    }

  },
  computed: {
    ...mapGetters(['requests']),
    displayed() {
      let start = (this.page - 1) * this.perPage;
      let end = start + this.perPage;
      let list = this.pageList.slice(start, end)
      this.start = start + 1;
      end > list.length ? this.end = this.start + list.length - 1 : this.end = end;
      return list
    }
  },
  created() {
    this.filterBy('all')
    this.fetchTenantRequests().then(() => {
      this.filterBy('all')
    })
  },
  beforeDestroy() {
  }
}