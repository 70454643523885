import { render, staticRenderFns } from "./requestDetail.component.vue?vue&type=template&id=787df0cc&scoped=true&"
import script from "./requestDetail.component.js?vue&type=script&lang=js&"
export * from "./requestDetail.component.js?vue&type=script&lang=js&"
import style0 from "./requestDetail.component.scss?vue&type=style&index=0&id=787df0cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "787df0cc",
  null
  
)

export default component.exports