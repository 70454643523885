import axios from "axios";
import { mapActions, mapGetters } from "vuex"

export default {
  name: 'Register',
  props: {
    account: String
  },
  components: {
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      banks: Array(),
      bankName: '',
      businessName: '',
      accountNumber: '',
      accountName: '',
      step: 1,
      profile: ''
    }
  },
  methods: {
    ...mapActions(['register', 'registerPropertyOwner', 'verifyAccountNumber', 'verifyEmail']),
    next() {
      if(this.confirmPassword != '' || this.email != '') {
        this.step++
      }
    },
    verifyMail() {
      if(this.email != '') {
        this.verifyEmail(this.email.toLowerCase()).then((response) => {
          if (!response) {
            this.email = ''
          }
        })
      }
    },
    callBanks() {
      const bankRequest = axios.get('https://api.paystack.co/bank')
      bankRequest.then((bank) => {
        bank.data.data.forEach(bank => {
          this.banks.push(bank)
        });
      })
    },
    submit() {
      let postData = new FormData();
      postData.append('email', this.email.toLowerCase())
      postData.append('password', this.password)
      postData.append('phone_number', this.phone)
      postData.append('re_password', this.confirmPassword)
      postData.append('first_name', this.firstName)
      postData.append('last_name', this.lastName)
      this.register(postData).then((profile) => {
        this.step++
        let account = new FormData()
        account.append('email', this.email.toLowerCase())
        account.append('password', this.password)
        account.append('account_number', this.accountNumber)
        account.append('bank_name', this.bankName)
        account.append('business_name', this.businessName || `${profile.data.first_name} ${profile.data.last_name} Properties`)
        account.append('user', profile.data.user_id)
        this.registerPropertyOwner(account)
      })
    },
    verifyAccount() {
      let account_number = new FormData()
      if (this.accountNumber.length == 10) {
        account_number.append('account_number', this.accountNumber)
        account_number.append('bank_name', this.bankName)
        this.verifyAccountNumber(account_number).then((account_name) => {
          this.accountName = account_name
        })
      }
    }
  },
  computed: {
    ...mapGetters(["loading"])
  },
  created() {
    this.callBanks()
  },
  beforeDestroy() {
  }
}