import PropertyOwner from "./propertyOwner";
import Tenant from "./tenant";

export default class Request {
    id = String;
    date = Date;
    date_resolved = Date;
    title = String;
    description = String;
    type = String;
    status = String;
    status = String;
    tenant = Tenant;
    property_owner = PropertyOwner;

    constructor(request) {
        this.id = request.request_id;
        this.status = request.status;
        this.type = request.type;
        this.description = request.description;
        this.title = request.title;
        this.date_resolved = request.date_resolved;
        this.date = new Date(request.date);
        this.status = request.status;
        this.tenant = new Tenant(request.tenant);
        this.property_owner = new PropertyOwner(request.property_owner);
    }
}