import PropertyOwner from "./propertyOwner";
import Unit from "./unit";

export default class Property {
    id = String;
    name = String;
    main_image = String;
    street_address = String;
    address_line_2 = String;
    city = String;
    state = String;
    property_owner = PropertyOwner;
    vacant = Number || 0;
    occupied = Number || 0;
    unlisted = Number || 0;
    units = Array()
    
    constructor(property) {
        this.id = property.property_id;
        this.name = property.name || 'Wayne Manor';
        this.main_image = property.main_image;
        this.street_address = property.street_address;
        this.address_line_2 = property.address_line_2;
        this.city = property.city;
        this.state = property.state;
        this.property_owner = property.property_owner;
        property.units.forEach(unit => {
            this.units.push(new Unit(unit))
        });
    }
}