var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"add-property"}},[_c('div',{staticClass:"back",on:{"click":function($event){return _vm.navigateBack()}}},[_c('unicon',{staticClass:"icon",attrs:{"name":"angle-left"}}),_c('span',[_vm._v(" Back ")])],1),_c('h1',[_vm._v("ADD NEW PROPERTY")]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"info"},[_c('observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addProperty)}}},[_c('validator',{staticClass:"input",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("Property Name *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.property_name),expression:"property_name"}],class:{ 'is-true': errors[0] },attrs:{"required":"","type":"text","name":"Property Name"},domProps:{"value":(_vm.property_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.property_name=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validator',{staticClass:"input",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("Street Address *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.street_address),expression:"street_address"}],class:{ 'is-true': errors[0] },attrs:{"required":"","type":"text","name":"Street Address"},domProps:{"value":(_vm.street_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.street_address=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validator',{staticClass:"input"},[_c('span',[_vm._v("Address Line 2")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address_line_2),expression:"address_line_2"}],attrs:{"type":"text","name":"Address Line 2"},domProps:{"value":(_vm.address_line_2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address_line_2=$event.target.value}}})]),_c('div',{staticClass:"join-inputs"},[_c('validator',{staticClass:"input mid",attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("City *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],class:{ 'is-true': errors[0] },attrs:{"required":"","type":"text","name":"City"},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.city=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validator',{staticClass:"input mid",attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("State *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.state),expression:"state"}],class:{ 'is-true': errors[0] },attrs:{"required":"","type":"text","name":"State"},domProps:{"value":(_vm.state)},on:{"input":function($event){if($event.target.composing){ return; }_vm.state=$event.target.value}}}),_c('p',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"button"},[_c('div',{staticClass:"button-icon"},[(_vm.loading)?_c('Spinner'):_c('unicon',{staticClass:"icon",attrs:{"name":"building"}})],1),_c('span',{staticClass:"action"},[_vm._v(" Create Property ")])])],1)]}}])})],1),_c('div',{staticClass:"image",on:{"click":_vm.uploadImage}},[_c('span',{class:{ uploaded: _vm.property_image != '' }},[_vm._v(" Click to upload ")]),_c('img',{class:{ uploaded: _vm.property_image != '' },attrs:{"src":_vm.display_image,"alt":""}}),_c('input',{attrs:{"type":"file","name":"","id":"image","accept":"image/x-png,image/jpeg","required":""},on:{"change":_vm.selectImage}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }