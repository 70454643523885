export default {
  name: 'SeeThrough',
  props: {
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  }
}