import { Role } from "./role.js";
import User from "./user.js"

export default class Tenant {
    id = String;
    avi = String;
    dateOfBirth = Date;
    occupation = String;
    nextOfKinFullName = String;
    nextOfKinPhoneNumber = String;
    nextOfKinAddress = String;
    profile = User;

    constructor(tenant) {
        this.id = tenant.tenant_id;
        this.avi = tenant.avi || "https://res.cloudinary.com/yugee-properties/image/upload/v1620743890/default-avi1.jpg";
        this.dateOfBirth = tenant.date_of_birth;
        this.occupation = tenant.occupation;
        this.nextOfKinFullName = tenant.next_of_kin_full_name;
        this.nextOfKinPhoneNumber = tenant.next_of_kin_phone_number;
        this.nextOfKinAddress = tenant.next_of_kin_address;
        this.profile = new User(tenant.user, Role.tenant);
    }
}