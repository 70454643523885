export default {
  name: 'Auth',
  props: {
    account: String
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    loginTo(account) {
      this.$router.push({path: "/login"})
      this.$emit('modify', account)
    },
  },
  computed: {
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
