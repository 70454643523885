import { mapActions } from "vuex"
import Unit from "@/classes/unit"
import store from "../../store"

export default {
  name: 'UnitDetail',
  props: {
    propertyId: {
      type: String,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      activeUnit: '',
      name: String(),
      updated: false,
      overpriced: Boolean
    }
  },
  methods: {
    ...mapActions(['fetchProperties', 'uploadUnits', 'deleteUnit']),
    navigateToProperty(propertyId) {
      if(this.updated) {
        this.$router.push({ path: `/p/properties/${propertyId}` })
      } else {
        store.dispatch('warningAlert', 'Please fill in empty fields')
        document.getElementById('saveUnits').click()
      }
    },
    newUnit() {
      let tempUnit = new Unit(1)
      tempUnit.status = "Available"
      tempUnit.property = this.propertyId
      tempUnit.is_published = true
      this.units.push(tempUnit)
      this.selectUnit(tempUnit)
    },
    removeUnit(unit) {
      if (unit.status == 'Occupied') {
        store.dispatch('errorAlert', 'This Unit is Occupied')
      } else if (this.units.length == 1) {
        store.dispatch('errorAlert', 'You must have at least one unit')
      } else {
        let remove = confirm("Are you sure you want to delete this unit");
        if (remove == true) {
          unit.id !== undefined ? this.deleteUnit(unit.id) : null
          this.units.splice(this.units.indexOf(unit), 1)
          this.selectUnit(this.units[this.units.length - 1])
          store.dispatch('successAlert', 'Unit deleted successfully')
        }
      }
    },
    duplicateUnit(unit) {
      let tempUnit = new Unit(JSON.parse(JSON.stringify(unit)))
      tempUnit.status = "Available"
      this.units.push(tempUnit)
      this.selectUnit(tempUnit)
    },
    saveUnits() {
      let newUnits = this.units.filter(
        unit => unit.id === undefined
      )
      let existingUnits = this.units.filter(
        unit => unit.id !== undefined
      )
      let formData = {
        'newUnits': newUnits,
        'existingUnits': existingUnits,
        'property': this.propertyId
      }
      for(let i = 0; i < this.units.length; i++) {
        let price = (+this.units[i].price + +this.units[i].optional_fees + (this.units[i].price * 0.1))
        if (price >= 9999999) {
          store.dispatch('errorAlert', `The cost of ${this.units[i].title} cannot be processed by our Payment Provider. Please adjust prices accordingly.`)
          this.selectUnit(this.units[i])
          this.overpriced = true;
          break;
        }
        this.overpriced = false;
      }
      if(this.overpriced == false) {
        this.uploadUnits(formData).then(() => {
          this.fetchProperties()
        });
        this.updated = true;
      }
    },
    selectUnit(unit){
      this.activeUnit = unit;
    }
  },
  computed: {
    units() {
      let property = this.$store.state.properties.properties.find(
        property => property.id === this.propertyId
      )
      this.name = property.name;
      if (property) {
        return property.units
      } else {
        return Array()
      }
    },
  },
  created() {
    this.fetchProperties().then(() => {
      this.units.length > 0 ? this.selectUnit(this.units[0]) : this.newUnit();
    })
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
