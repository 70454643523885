import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ForgotPassword',
  props: {
    account: String
  },
  components: {
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    ...mapActions(['forgotPassword']),
    async resetLink() {
      let postData = new FormData;
      postData.append('email', this.email.toLowerCase())
      this.forgotPassword(postData);
    },
  },
  computed: {
    ...mapGetters(["loading"])
  },
  mounted() {
  },
  beforeDestroy() {
  }
}