import { mapActions, mapGetters } from "vuex"

export default {
  name: 'Login',
  props: {
    account: String
  },
  components: {
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    ...mapActions(['logIn']),
    login() {
      let postData = new FormData
      postData.append('email', this.email.toLowerCase())
      postData.append('password', this.password)
      this.logIn(postData)
    },
    backToAuth() {
      this.$router.push({ name: 'Auth' })
    },
    navigateToForgotPassword() {
      this.$router.push({ name: 'ForgotPassword' })
    }
  },
  computed: {
    ...mapGetters(["loading"])
  },
  created() {},
  mounted() {
  },
  beforeDestroy() {
  }
}
