import { mapActions, mapGetters } from "vuex"
import ClickOutside from 'vue-click-outside'
import Dropdown from "@/components/Dropdown/dropdown.component.vue"

export default {
  name: 'TenantDetail',
  props: {
    tenantId: {
      type: String,
      required: true
    }
  },
  components: {
    Dropdown
  },
  data() {
    return {
      actions: ['Renew Rent', 'Remove Tenant'],
      dropdown: false
    }
  },
  methods: {
    ...mapActions(['fetchProperties', 'fetchAllRent', 'renewRent', 'evictTenant', 'quitNotice']),
    goToTenants() {
      this.$router.push({ path: `/p/tenants` })
    },
    toggle() {
      this.$refs.dropdown.toggleDropDown();
      this.dropdown = !this.dropdown
    },
    hide() {
      this.$refs.dropdown.hideDropDown();
      this.dropdown = false
    },
    performAction(action) {
      switch (action) {
        case 'Renew Rent':
          this.renew()
          break;
        case 'Remove Tenant':
          this.evict()
          break;
        case 'Quit Notice':
          this.quit()
          break;
      }
      this.hide()
    },
    renew() {
      let formData = new FormData()
      let latestRent = this.history[0]
      if (latestRent.status == 'Unpaid') {
        this.$store.dispatch('errorAlert', 'Tenant has to pay for current rent before it can be renewed.')
      } else {
        formData.append('rent', latestRent.id)
        formData.append('optional_fees', latestRent.optional_fees > 0 ? true : false)
        this.renewRent(formData)
      }
    },
    evict() {
      let latestRent = this.history[0]
      if (latestRent.status == 'Unpaid') {
        this.evictTenant(this.history[0].id)
      } else {
        this.$store.dispatch('errorAlert', 'Tenant with can active rent cannot be removed.')
      }

    },
    quit() {
      this.quitNotice('quit')
    }
  },
  computed: {
    ...mapGetters(['allRent']),
    tenant() {
      let rent = this.allRent.find(
        rent => rent.tenant.id === this.tenantId
      )
      if (rent) {
        return rent.tenant
      }
    },
    activeRent() {
      return this.history.find(
        rent => new Date(rent.terminationDate) > new Date(this.today) && new Date(rent.date) < new Date(this.today)
      )
    },
    history() {
      let history = this.allRent.filter(
        rent => rent.tenant.id === this.tenantId
      )
      history.sort((a, b) => b.terminationDate - a.terminationDate)
      return history
    }
  },
  created() {
    this.fetchAllRent();
    this.fetchProperties();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  directives: {
    ClickOutside
  }
}
