import Header from '@/components/Header/header.component.vue'
import Navigation from '@/components/Navigation/navigation.component.vue'
import Alert from '@/components/Alert/alert.component.vue'
import { mapGetters } from 'vuex';
import store from './store';

export default {
    name: 'App',
    components: {
        Header,
        Navigation,
        Alert
    },
    data() {
        const noOverlayPages = ['Home', 'Auth', 'Login', 'Error', 'ResetPassword', 'ForgotPassword', 'Register', 'ActivateAccount'];
        const enableOverlay = false;
        const isExtended = false;

        return {
            isExtended,
            noOverlayPages,
            enableOverlay,
            refreshing: false,
            registration: null,
            updateExists: false
        }
    },
    methods: {
        extend() {
            this.isExtended = !this.isExtended;
        },
        disableComponent() {
            if (this.noOverlayPages.includes(this.$route.name)) {
                this.enableOverlay = false;
            } else {
                this.enableOverlay = true;
            }
        },
        updateAvailable(e) {
            this.registration = e.detail;
            this.updateExists = true;
            this.refreshApp()
        },
        refreshApp() {
            this.updateExists = false;
            if (!this.registration || !this.registration.waiting) { return; }
            this.registration.waiting.postMessage('skipWaiting');
        },
        checkForUpdates() {
            document.addEventListener(
                'swUpdated', this.updateAvailable, { once: true }
            );
            if (navigator.serviceWorker) {
                navigator.serviceWorker.addEventListener(
                    'controllerchange', () => {
                        if (this.refreshing) return;
                        this.refreshing = true;
                        window.location.reload();
                    }
                );
            }
        }
    },
    computed: {
        ...mapGetters(['alert']),
    },
    updated() {
        this.disableComponent()
    },
    created() {
        this.checkForUpdates()
        this.disableComponent()
        store.dispatch('refresh')
        setInterval(() => {
            store.dispatch('refresh')
        }, 180000);
    }
}