import Tenant from "./tenant";
import Unit from "./unit";

export default class Rent {
    id = String;
    date = Date;
    amountPaid = Number;
    total_amount = Number;
    optional_fees = Number;
    status = String;
    terminationDate = Date;
    identificationCard = String;
    signedAgreement = String;
    otherDocument = String;
    tenant = Tenant;
    unit = Unit;


    constructor(rent) {
        this.id = rent.rent_id;
        this.date = new Date(rent.date);
        this.status = rent.status;
        this.total_amount = rent.total_amount;
        this.optional_fees = rent.optional_fees;
        this.amountPaid = rent.amount_paid;
        this.terminationDate = new Date(rent.termination_date);
        this.identificationCard = rent.identification_card;
        this.signedAgreement = rent.signed_agreement;
        this.otherDocument = rent.other_document;
        this.tenant = new Tenant(rent.tenant);
        this.unit = new Unit(rent.unit);
    }
}