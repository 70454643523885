import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export default {
  name: 'Spinner',
  props: {
    size: Number
  },
  data() {
    return{
      spinner: faSpinner,
    }
  },
  methods: {
  },
  created() {
  }
}