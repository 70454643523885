import { mapActions } from "vuex"

export default {
  name: 'PropertyDetail',
  props: {
    propertyId: {
      type: String,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchProperties', 'deleteProperty', 'fetchTenants']),
    addTenant() {
      this.$router.push({ path: `/p/${this.propertyId}/tenants/new` })
    },
    error() {
      setTimeout(() => {
        this.property == undefined ? this.$router.push({ name: 'Error' }) : '';
      }, 1000);
    },
    goToUnits() {
      this.$router.push({ path: `/p/properties/${this.propertyId}/units` })
    },
    goToProperties() {
      this.$router.push({ path: `/p/properties` })
    },
    goToTenant(tenantId) {
      this.$router.push({ path: `/p/tenants/${tenantId}` })
    },
    del() {
      let occupied = false;
      this.property.units.forEach((unit) => {
        if(unit.status == 'Occupied') {
          return occupied = true
        }
      })
      if (occupied) {
        this.$store.dispatch('errorAlert', 'This property is currently occupied.')
      } else {
        let remove = confirm("Are you sure you want to delete this property? This action cannot be undone.");
        if (remove == true) {
          this.deleteProperty(this.propertyId)
        }
      }
    }
  },
  computed: {
    property() {
      return this.$store.state.properties.properties.find(
        property => property.id === this.propertyId
      )
    },
    tenants() {
      let tenants = this.$store.state.properties.rents.filter(
        rent => rent.unit.property === this.propertyId && rent.unit.status == "Occupied"
      )
      return Array.from(new Set(tenants.map(rent => rent.tenant.id))).map(id => {
        return tenants.find(rent => rent.tenant.id === id)
      })

    },
    occupied() {
      let occupied = 0;
      this.property.units.forEach(unit => {
        unit.status == "Occupied" ? occupied += 1 : null;
      });
      return occupied;
    },
    balance_due() {
      let balance = 0;
      let active_rents = this.tenants.filter(
        rent => new Date(rent.terminationDate) > new Date(this.today)
      )
      active_rents.forEach(rent => {
        balance += (rent.total_amount - rent.amountPaid)
      });
      return balance
    }
  },
  created() {
    this.fetchTenants();
    this.fetchProperties();
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
