import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from './modules/auth'
import alert from './modules/alert'
import properties from './modules/properties'
import tenant from './modules/tenant'
import requests from './modules/requests'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    route: state.route,
    lastLaunch: new Date().valueOf()
  })
})

export default new Vuex.Store({
  state: {
    loading: false,
    logoutLoader: false,
  },
  getters: {
    loading: state => state.loading,
    logoutLoader: state => state.logoutLoader,
  },
  actions: {
    load({ commit }) {
      commit('load')
    },
    noauthload({ commit }) {
      commit('load')
    },
    unload({ commit }) {
      commit('unload')
    },
    logoutLoading({ commit }) {
      commit('logoutLoading')
    }
  },
  mutations: {
    load: (state) => {
      state.loading = true;
    },
    unload: (state) => {
      state.loading = false;
    },
    logoutLoading: (state) => {
      state.logoutLoader = true;
      setTimeout(() => {
        state.logoutLoader = false;
      }, 4000);
    }
  },
  modules: {
    auth,
    alert,
    properties,
    tenant,
    requests
  },
  plugins: [vuexLocal.plugin]
})
