import { mapActions, mapGetters } from 'vuex'
import { AuthService } from '../../../services/auth.service';

export default {
  name: 'Dashboard-Tenant',
  props: {
    isExtended: Boolean
  },
  data() {
    return {
      paying: false,
    }
  },
  methods: {
    ...mapActions(["activeRent", "fetchTenantRequests", "payRent"]),
    goToRequest(requestId) {
      this.$router.push({path: `/u/requests/${requestId}`})
    },
    newRequest() {
      this.$router.push({ path: `/u/requests/new` })
    },
    pay() {
      this.paying = true;
      let formData = new FormData()
      formData.append('rent', this.currentRent.id)
      this.payRent(formData).then(() => {
        this.paying = false;
      });
    },
  },
  computed: {
    ...mapGetters(["activeProperty", "requests"]),
    greeting() {
      let time = new Date().getHours();
      let greeting = 'Hello'
      if ( time < 12) {
        greeting = "Good Morning"
      } else if (time < 16) {
        greeting = "Good Afternoon"
      } else if (time < 22 ) {
        greeting = "Good Evening"
      } else {
        greeting = "Good Night"
      }
      return `${greeting}, ${new AuthService().getUser().profile.firstName}`
    },
    currentRent() {
      if (this.activeProperty.rent) {
        return this.activeProperty.rent
      } else {
        return ''
      }
    },
    newestRequests() {
      return this.requests.slice(0, 4);
    }

  },
  created() {
    this.activeRent();
    this.fetchTenantRequests();
  },
  mounted() {
  },
  beforeDestroy() {
  }
}