import ActiveProperty from "../classes/activeProperty";
import Property from "../classes/property";
import PropertyOwner from "../classes/propertyOwner";
import Rent from "../classes/rent";
import Request from "../classes/request";
import Tenant from "../classes/tenant";
import User from "../classes/user";

export class ResponseToObjectService {

    convertToUser(response) {
        let user = response.data;
        return new User(user)
    }

    convertToActiveProperty(response) {
        return new ActiveProperty(response.property.data, response.activeRent.data.activeRent)
    }

    convertToPropertiesArray(response) {
        let properties = Array();
        response.data.results.forEach(property => {
            properties.push(new Property(property))
        });

        return properties
    }

    convertToRentsArray(response) {
        let rents = Array();
        let queryset = response.data.rents;
        if (queryset) {
            queryset.forEach(rent => {
                rents.push(new Rent(rent))
            });
        }

        return rents
    }

    convertToRequestsArray(response) {
        let requests = Array();
        let queryset = response.data.requests;
        if (queryset) {
            queryset.forEach(request => {
                requests.push(new Request(request))
            });
        }

        return requests
    }

    convertToTenantsArray(response) {
        let tenants = Array();
        let queryset = response.data.tenants;
        if (queryset) {
            queryset.forEach(tenant => {
                tenants.push(new Tenant(tenant))
            });
        }

        return tenants
    }

    convertToPropertyOwner(response) {
        return new PropertyOwner(response.data.user)
    }

    convertToTenant(response) {
        return new Tenant(response.data.user)
    }
}